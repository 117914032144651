$font-family: 'Raleway', sans-serif;
$white: #FFFFFF;
$black: #000000;
$regal-blue: #004D6D;
$iceberg: #DDF3F1;
$boston-blue: #3B92B2;

$logo-width: 160px;
$logo-width-desktop: 100px;
$logo-width-tablet: 75px;

$logo-padding: 19px;
$logo-padding-desktop: 9px;
$logo-padding-tablet: 0;

$page-bg-width: calc($logo-width + ($logo-width / 2));
$page-bg-width-desktop: calc($logo-width-desktop + ($logo-width-desktop / 2));
$page-bg-width-tablet: calc($logo-width-tablet + ($logo-width-tablet / 2));

$footer-size: 80px;
$footer-tablet-size: 110px;
