.header {
    &__logo, &__logo.logo {
        position: fixed;
        z-index: 999;
        top: 71px;
        left: 0;

        @include devices(tablet) {
            top: 0;
        }
    }
}