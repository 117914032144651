.logo {
    line-height: 0;
    padding: $logo-padding;
    position: relative;
    background-color: $white;

    @include devices(large) {
        padding: $logo-padding-desktop;
    }

    @include devices(tablet) {
        padding: $logo-padding-tablet;
        background-color: transparent;
    }

    &::after {
        @include devices(tablet) {
            content: '';
            width: calc(100% - 1px);
            height: calc(100% - 1px);
            background-color: #004b73;
            position: absolute;
            top: 0;
            z-index: 5;
        }
    }

    &._show-dark {
        &::after {
            @include devices(tablet) {
                background-color: #fff;
            }
        }

        ._dark {
            @include devices(tablet) {
                opacity: 1;
            }
        }
        
        ._light {
            opacity: 0;
        }
    }

    &__link {
        display: block;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: $logo-width;
            padding-top: 204%;

            @include devices(large) {
                width: $logo-width-desktop;
            }

            @include devices(tablet) {
                width: $logo-width-tablet;
            }
        }
    }

    &__svg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        transition: opacity 244ms ease-in-out;

        &._light {
            opacity: 0;

            @include devices(tablet) {
                opacity: 1;
            }
        }

        &._dark {
            @include devices(tablet) {
                opacity: 0;
            }
        }
    }
}