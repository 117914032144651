.info-block {
    padding: 27px 20px 30px 45px;
    background-color: $iceberg;
    position: relative;

    @include devices(tablet) {
        width: calc(100% + 20px + 16px);
        left: -18px;
        padding-right: 32px;
        padding-bottom: 33px;
    }


    &:before {
        content: '';
        width: 20px;
        height: 100%;
        background-color: $boston-blue;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__title {
        font-weight: 700;
    }

    &__title+&__content {
        margin-top: 26px;
    }

    &+.info-block {
        margin-top: 50px;
    }
}

p+.info-block {
    margin-top: 39px;
}