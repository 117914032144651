.page-background {
    width: $page-bg-width;
    overflow: hidden;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;


    @include devices(tablet) {
        display: none;
    }

    @include devices(laptop) {
        width: $page-bg-width-tablet;
    }
    
    @include devices(large) {
        width: $page-bg-width-desktop;
    }

    img {
        height: 100%;

        @include devices(tablet) {
            width: 100%;
            height: auto;
        }
    }

    &._mobile {
        display: none;
        width: 100%;

        @include devices(tablet) {
            display: block;
        }
    }
}