.main {
    padding-left: 431px;
    padding-right: 84px;

    @include devices(large) {
        padding-left: 235px;
    }
    
    @include devices(tablet) {
        padding-left: 93px;
        padding-right: 16px;
    }
}