@import "~normalize.css/normalize.css";
@import "~12-column-grid/src/grid.scss";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700&display=swap');

@import "./_variables.scss";
@import "./_devices.scss";
@import "./_d.scss";
@import "./typography/_index.scss";
@import "./components/_index.scss";
@import "./blocks/_index.scss";

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  color: $regal-blue;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  letter-spacing: 0.018em;
  line-height: 1.5;
  min-height: 100vh;
  position: relative;

  @include devices(tablet) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.66;
    padding-bottom: 137px;
  }

  &:after {
    content: '';
    display: block;
    height: $footer-size;
  }
}

body::-webkit-scrollbar {
  width: 20px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: $regal-blue;
}

.app {
  overflow: hidden;
}

.row,
.grid {
  @include devices(tablet) {
    column-gap: 0;
  }
}

._top {
  overflow: hidden;
  position: relative;

  @include devices(tablet) {
    padding-bottom: 162px;
  }
}

p {
  margin-bottom: 25px;

  @include devices(tablet) {
    margin-bottom: 27px;
  }
}