.button {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $regal-blue;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 0 solid transparent;
    border-radius: 0;
    background-color: transparent;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}