.d {
    &__desktop {
        display: block;

        @include devices(tablet) {
            display: none;
        }
    }

    &__tablet {
        display: none;

        @include devices(tablet) {
            display: block;
        }
    }
}