.content {

    &_margin {
        margin-top: 282px;

        @include devices(tablet) {
            margin-top: 26px;
        }
    }

    ._top & {
        color: $regal-blue;

        @include devices(tablet) {
            color: $white;
        }
    }
}