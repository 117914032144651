.to-top {
    pointer-events: none;
    cursor: pointer;
    line-height: 0;
    position: fixed;
    bottom: 13px;
    right: 13px;
    opacity: 0;
    transition: opacity 244ms ease-in-out;

    @include devices(tablet) {
        bottom: 24px;
        right: 0;
    }

    &._show {
        pointer-events: unset;
        opacity: 1 !important;
    }

    &__button {
        padding: 21px 24px 15px;
        background-color: $boston-blue;

        svg {
            width: 19px;
            height: auto;
            max-width: 100%;
        }
    }
}