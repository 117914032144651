@mixin devices ($breakpoint) {
    @if $breakpoint ==large {
        @media only screen and (max-width: 1400px) {
            @content;
        }
    }

    @if $breakpoint ==desktop {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }

    @if $breakpoint ==laptop {
        @media only screen and (max-width: 992px) {
            @content;
        }
    }

    @if $breakpoint ==tablet {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }

    @if $breakpoint ==mobile {
        @media only screen and (max-width: 576px) {
            @content;
        }
    }
}