h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 20px 0 0;
    line-height: 1.6;
        
    @include devices(tablet) {
        letter-spacing: 0.02em;
    }
}

.h1 {
    font-size: 40px;
    margin: 84px 0 73px;
    line-height: 1;
        
    @include devices(tablet) {
        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0.02em;
        margin-top: 104px;
        margin-bottom: 32px;
    }
}

.h2 {
    font-size: 40px;
    margin: 84px 0 58px;
    line-height: 1;
        
    @include devices(tablet) {
        font-size: 23px;
        font-weight: 600;
        margin-top: 67px;
        margin-bottom: 46px;
    }
}