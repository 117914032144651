.footer {
    width: 100%;
    height: $footer-size;
    position: absolute;
    bottom: 0;

    @include devices(tablet) {
        height: $footer-tablet-size;
    }

    &__row {
        padding-left: 431px;
        padding-right: 84px;

        @include devices(large) {
            padding-left: 235px;
        }

        @include devices(tablet) {
            padding-left: 19px;
            padding-right: 110px;
        }
    }
}